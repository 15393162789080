<template>
  <div class="privacy">
    <h1
      style="
        margin-top: 17pt;
        margin-bottom: 16.5pt;
        text-align: center;
        page-break-inside: avoid;
        page-break-after: avoid;
        line-height: 240%;
        font-size: 22pt;
      "
    >
      <span style="font-family: 宋体">树枝记账软件隐私协议</span>
    </h1>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">更新日期：</span><span>2023-3-12</span
      ><span style="-aw-import: spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: 宋体">生效日期：</span><span>2023-3-12</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">引言</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >树枝记账重视用户的隐私。您在使用我们的服务时，我们可能会收集和使用您的相关信息。我们希望通过本《隐私政策》向您说明，在使用我们的服务时，我们如何收集、使用、储存和分享这些信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。本《隐私政策》与您所使用的树枝记账服务息息相关，我们也希望您能够仔细阅读，并在需要时，按照本《隐私政策》的指引，作出您认为适当的选择。本《隐私政策》中涉及的相关技术词汇，我们尽量以简明扼要的表述向您解释，并提供了进一步说明的链接，以便您的理解。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >如果您不同意本隐私权政策的部分或全部内容，您应立即停止使用树枝记账软件服务并通过软件客服与我们联系。您通过勾选、点击确认、同意授权等方式确认本隐私权政策或直接使用及继续使用我们的服务，都表示您同意我们按照本《隐私政策》收集、使用、储存和分享您的信息。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >如您对本《隐私政策》或与本《隐私政策》相关的事宜有任何问题，请通过</span
      ><span>www.nilwy.com</span><span style="font-family: 宋体">与我们联系</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">我们可能收集的信息</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >我们提供服务时，可能会收集、储存和使用下列与您有关的信息。如果您不提供相关信息，可能无法注册成为我们的用户、享受我们提供的某些服务，或者即便我们可以继续向您提供一些服务，也无法达到该服务拟达到的效果。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">您提供的信息</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >您在注册我们的账户或使用我们的服务时，向我们提供的相关个人信息，例如电话号码、电子邮件等；</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >您通过我们的服务向其他方提供的共享信息，以及您使用我们的服务时所储存的信息。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">其他方分享的您的信息</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >其他方使用我们的服务时所提供有关您的共享信息。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">我们获取的您的信息</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >为了预防恶意程序，保障软件与服务的安全运行、提升运营的质量及效率，我们可能会收集如下信息：</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">日志信息指您使用我们服务时，系统可能会通过 </span
      ><span>cookies</span><span style="font-family: 宋体">、</span
      ><span>web beacon </span
      ><span style="font-family: 宋体">或其他方式自动采集的技术信息，包括：</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >设备或软件信息，例如您的移动设备、网页浏览器或您用于接入我们的服务的其他程序所提供的配置信息、您的</span
      ><span>IP</span
      ><span style="font-family: 宋体">地址和您的移动设备所用的版本和设备识别码；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >您在使用我们服务时搜索和浏览的信息，例如您使用的网页搜索词语、访问的社交媒体页面</span
      ><span>url</span
      ><span style="font-family: 宋体"
        >地址，以及您在使用我们服务时浏览或要求提供的其他信息和内容详情；</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">有关您曾使用的移动应用（</span><span>APP</span
      ><span style="font-family: 宋体"
        >）和其他软件的信息，以及您曾经使用该等移动应用和软件的信息；</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >您通过我们的服务进行通讯的信息，例如曾通讯的账号，以及通讯时间、数据和时长；</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >您通过我们的服务分享的内容所包含的信息（元数据），例如拍摄或上传的共享照片或录像的日期、时间或地点等。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >为了保障软件与服务的安全运行，我们会收集您的应用安装列表、设备型号、设备名称、
        设备唯一标识符（包括：</span
      ><span>IMEI</span><span style="font-family: 宋体">、</span><span>Android ID</span
      ><span style="font-family: 宋体">、</span><span>IDFA</span
      ><span style="font-family: 宋体">、设备</span><span>Mac</span
      ><span style="font-family: 宋体"
        >地址）、语言设置、字体设置、操作系统和应用程序版本、登录</span
      ><span>IP</span
      ><span style="font-family: 宋体">地址、接入网络的方式、设备运行进程信息。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">我们获取的设备权限</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >在具体提供服务的过程中我们可能需要调用您的一些设备权限，例如为上传图片的目的获得您的照片访问权限；为实现拍照和上传目的获得您的相机权限；为实现保存图片目的获取您的存储权限等，我们将在具体场景下以弹窗的形式询问并征得您的同意。您可以在设备的系统设置功能中选择打开或关闭相应的权限，从而允许或拒绝我们收集和使用您的个人信息。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">我们可能如何使用信息</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >我们可能将在向您提供服务的过程之中所收集的信息用作下列用途：</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">向您提供服务；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">帮助我们设计新服务，改善我们现有服务；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >使我们更加了解您如何接入和使用我们的服务，从而针对性地回应您的个性化需求，例如语言设定、个性化的帮助服务和指示，或对您和其他使用我们服务的用户作出其他方面的回应；</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >向您提供与您更加相关的广告以替代普遍投放的广告；</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >评估我们服务中的广告和其他促销及推广活动的效果，并加以改善；</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">软件认证或管理软件升级；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">让您参与有关我们产品和服务的调查。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >为了让您有更好的体验、改善我们的服务或您同意的其他用途，在符合相关法律法规的前提下，我们可能将通过我们的某一项服务所收集的信息，以汇集信息或者个性化的方式，用于我们的其他服务。例如，在您使用我们的一项服务时所收集的信息，可能在另一服务中用于向您提供特定内容，或向您展示与您相关的、非普遍推送的信息。您可以在自主选择关闭个性化推荐（打开树枝记账</span
      ><span>-</span><span style="font-family: 宋体">首页</span><span>-</span
      ><span style="font-family: 宋体">购物返现</span><span>-</span
      ><span style="font-family: 宋体">我的</span><span>-</span
      ><span style="font-family: 宋体">个性化推荐开关</span><span>-</span
      ><span style="font-family: 宋体"
        >点击关闭），当您选择关闭个性化推荐后，您无法享受个性化推荐服务，但仍可继续浏览非个性化推荐内容。如果我们在相关服务中提供了相应选项，您也可以授权我们将该服务所提供和储存的信息用于我们的其他服务。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >在你分享或者接收被分享的信息、参加活动等情形下，我们需要在本地访问您的剪切板，读取其中的口令、分享码和链接，以实现跳转、分享、活动联动等功能或者服务。仅在本地识别出剪贴板内容属于树枝记账跳转、分享、活动联动等指令时才会将其上传我们的服务器，除此之外我们不会上传剪切板的其他信息至服务器。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">当您在未登录的游客状态下使用</span><span>app</span
      ><span style="font-family: 宋体"
        >时，我们将不会收集您的订单等信息，也不会对您进行个性化推荐。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">您如何访问和控制自己的个人信息</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >我们将尽一切可能采取适当的技术手段，保证您可以访问、更新和更正自己的注册信息或使用我们的服务时提供的其他个人信息。在访问、更新、更正和删除您的个人信息时，我们可能会要求您进行身份验证，以保障您的账户安全。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">访问您的个人信息</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >您可以通过手机设备接入按以下所列方式自行访问您的个人信息：</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >账户信息——如果您希望访问或编辑您的账户中的个人基本资料信息、更改您的头像等，您可以通过登录账号点击“我的”进入个人主页后点击头像进入“个人资料”执行此类操作。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">更正或补充您的个人信息</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >当您发现我们处理的关于您的个人信息有错误时，您可以要求我们做出更正或补充。联系方式详见本政策“关于我们”部分。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">删除您的个人信息</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >您也可以通过注销账户的方式，取消我们收集和使用您的个人信息的全部授权。在您注销账户后，除法律法规另有规定或者另与您另有书面约定，我们将停止使用并删除您的个人信息。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >在下列情形中，您可以向我们提出删除您的个人信息的请求：</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >我们处理个人信息的行为违反法律法规或与您的约定的；</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >我们对您的个人信息的收集和使用，未能获得您明确同意的；</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >我们终止向您提供或您主动终止使用我们的产品或服务的。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >如果您无法通过上述路径访问、更新或删除该等个人信息，您可以随时通过后附联系方式与我们取得联系，我们将在十五日内回复您请求。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >当您删除个人信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。在您注销账号时我们将删除您的个人信息，但已经用于用户画像的匿名化处理信息或根据法律法规不得删除的情形除外。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >我们匿名化处理或删除您的个人信息时，如您的个人信息曾被共享，我们还会通知相关公司、组织或个人对您的个人信息做匿名化或删除处理。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">个人信息主体注销账户</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >您可随时通过手机客户端点击“我的”进入个人页面，再点击头像进入个人信息页面，然后点击页面最底部“注销账号”或联系我们注销此前注册的账户，联系方式详见本政策中列明的联系方式联系，在注销账户后我们将停止为您提供产品或服务，并依据您的要求删除您的个人信息，法律法规另有规定的除外。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">您个人信息的存储</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。在获得您的授权同意之前，您的个人信息不会向中华人民共和国境外传输和存储。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >您的相关信息保存将低于三年，如果超过三年我们将立即删除或做匿名化处理，但是您的个人账户注册信息在你申请注销前的账户持续存续期间，将由我们持续存储。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">我们可能分享的信息</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >除以下情形外，未经您同意，我们以及我们的关联公司不会与任何第三方分享您的个人信息：</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >我们以及我们的关联公司可能将您的个人信息与我们的关联公司、合作伙伴及第三方服务供应商、承包商及代理（例如代表我们发出电子邮件或推送通知的通讯服务提供商）分享，用作下列用途：</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">向您提供我们的服务；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">实现“我们可能如何使用信息”部分所述目的；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >履行我们在本《隐私政策》中的义务和行使我们的权利；</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">理解、维护和改善我们的服务；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">为了向您提供一键分享服务，我们的产品集成友盟</span
      ><span>+ U-Share SDK</span
      ><span style="font-family: 宋体">，将收集您的设备标识信息（如</span
      ><span>IMEI/android ID/IDFA</span
      ><span style="font-family: 宋体"
        >等）和您需要分享的社交账户公开信息，以便完成一键分享服务。为了您的信息安全，我们已与第三方</span
      ><span>SDK</span
      ><span style="font-family: 宋体"
        >服务商进行数据安全保密约定，这些公司会严格遵守我们的数据隐私和安全要求。除非得到您的同意，我们不会与其共享您的个人身份信息。为便于您更好地了解【友盟</span
      ><span>+</span
      ><span style="font-family: 宋体"
        >】采集的数据类型及用途，以及何保护您的个人信息，您可以登陆</span
      ><span>https://www.umeng.com/policy</span
      ><span style="font-family: 宋体">了解【友盟</span><span>+</span
      ><span style="font-family: 宋体">】隐私权政策；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >如我们或我们的关联公司与任何上述第三方分享您的个人信息，我们将努力确保该等第三方在使用您的个人信息时遵守本《隐私政策》及我们要求其遵守的其他适当的保密和安全措施；</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >随着我们业务的持续发展，我们以及我们的关联公司有可能进行合并、收购、资产转让或类似的交易，您的个人信息有可能作为此类交易的一部分而被转移；</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >我们或我们的关联公司还可能为以下需要保留、保存或披露您的个人信息：</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">遵守适用的法律法规；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">遵守法院命令或其他法律程序的规定；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">遵守相关政府机关的要求；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >为遵守适用的法律法规、维护社会公共利益、或保护我们或我们的集团公司、我们的客户、其他用户或雇员的人身和财产安全或合法权益所合理必需的用途。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">信息安全</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >我们仅在本《隐私政策》所述目的所必需期间和法律法规要求的时限内保留您的个人信息。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >我们使用各种安全技术和程序，以防信息的丢失、不当使用、未经授权阅览或披露。例如，在某些服务中，我们将利用加密技术（例如</span
      ><span>SSL</span
      ><span style="font-family: 宋体"
        >）来保护您提供的个人信息。但请您谅解，由于技术的限制以及以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。当您发现安全弱点和可疑事件时请及时报告，不要私自尝试验证弱点。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">我们可能如何收集信息</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>cookies</span><span style="font-family: 宋体">和</span><span>web beacon</span
      ><span style="font-family: 宋体">的使用</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">我们或我们的第三方合作伙伴，可能通过 </span
      ><span>cookies </span><span style="font-family: 宋体">和 </span
      ><span>web beacon </span
      ><span style="font-family: 宋体"
        >收集和使用您的信息，并将该等信息储存为日志信息。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">我们使用自己的 </span><span>cookies </span
      ><span style="font-family: 宋体">和 </span><span>web beacon</span
      ><span style="font-family: 宋体"
        >，目的是为您提供更个性化的用户体验和服务，并用于以下用途：</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">记住您的身份。例如：</span><span>cookies </span
      ><span style="font-family: 宋体">和 </span><span>web beacon </span
      ><span style="font-family: 宋体">有助于我们辨认您作为我们的注册用户的身份；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">分析您使用我们服务的情况。例如，我们可利用 </span
      ><span>cookies </span><span style="font-family: 宋体">和 </span
      ><span>web beacon </span
      ><span style="font-family: 宋体"
        >来了解您使用我们的服务进行什么活动、或哪些网页或服务最受欢迎；</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">广告优化。</span><span>cookies </span
      ><span style="font-family: 宋体">和 </span><span>web beacon </span
      ><span style="font-family: 宋体"
        >有助于我们根据您的信息，向您提供与您相关的广告而非进行普遍的广告投放。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">我们为上述目的使用 </span><span>cookies </span
      ><span style="font-family: 宋体">和 </span><span>web beacon </span
      ><span style="font-family: 宋体">的同时，可能将通过 </span><span>cookies </span
      ><span style="font-family: 宋体">和 </span><span>web beacon </span
      ><span style="font-family: 宋体"
        >收集的非个人身份信息，经统计加工后提供给广告商或其他合作伙伴，用于分析用户如何使用我们的服务，并用于广告服务。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >我们的产品和服务上可能会有广告商或其他合作方放置的 </span
      ><span>cookies </span><span style="font-family: 宋体">和 </span
      ><span>web beacon</span><span style="font-family: 宋体">。这些 </span
      ><span>cookies </span><span style="font-family: 宋体">和 </span
      ><span>web beacon </span
      ><span style="font-family: 宋体"
        >可能会收集与您相关的非个人身份信息，以用于分析用户如何使用该等服务，或用于评估广告服务的效果。这些第三方 </span
      ><span>cookies </span><span style="font-family: 宋体">和 </span
      ><span>web beacon </span
      ><span style="font-family: 宋体"
        >收集和使用该等信息，不受本《隐私政策》约束，而是受相关使用者的隐私政策约束，我们不对第三方的 </span
      ><span>cookies </span><span style="font-family: 宋体">或 </span
      ><span>web beacon </span><span style="font-family: 宋体">承担责任。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">您可以通过浏览器设置拒绝或管理 </span
      ><span>cookies </span><span style="font-family: 宋体">或 </span
      ><span>web beacon</span><span style="font-family: 宋体">。但请注意，如果停用 </span
      ><span>cookies </span><span style="font-family: 宋体">或 </span
      ><span>web beacon</span
      ><span style="font-family: 宋体"
        >，您有可能无法享受最佳的服务体验，某些服务也可能无法正常使用。同时，您还会收到同样数量的广告，但这些广告与您的相关性会降低。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">第三方</span><span>SDK</span
      ><span style="font-family: 宋体">的使用</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >为了保障我们客户端的稳定运行、功能实现，使您能够使用和享受更多的服务及功能，我们的应用中会嵌入授权合作伙伴的</span
      ><span>SDK</span><span style="font-family: 宋体">或其他应用程序。此时，第三方</span
      ><span>SDK</span
      ><span style="font-family: 宋体">服务商可能会收集您的相关信息。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">具体第三方</span><span>SDK</span
      ><span style="font-family: 宋体">的使用情况如下：</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">友盟</span><span>+SDK</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">第三方主体：友盟同欣（北京）科技有限公司</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >使用目的：一键分享服务、统计分析服务并通过地理位置校准报表数据准确性，提供基础反作弊能力</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">收集信息类型：设备</span><span>Mac</span
      ><span style="font-family: 宋体">地址、唯一设备识别码（</span
      ><span>IMEI/android ID/IDFA/OPENUDID/GUID</span
      ><span style="font-family: 宋体">、</span><span>SIM </span
      ><span style="font-family: 宋体">卡 </span><span>IMSI </span
      ><span style="font-family: 宋体"
        >信息、设备序列号）、需要分享的社交账户公开信息、设备运行进程信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">官方隐私政策：</span
      ><span>https://www.umeng.com/policy</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt"><span>Umeng AnalyticsSDK</span></p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">第三方主体：友盟同欣（北京）科技有限公司</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >使用目的：一键分享服务、统计分析服务并通过地理位置校准报表数据准确性，提供基础反作弊能力</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">收集信息类型：设备</span><span>Mac</span
      ><span style="font-family: 宋体">地址、唯一设备识别码（</span
      ><span>IMEI/android ID/IDFA/OPENUDID/GUID</span
      ><span style="font-family: 宋体">、</span><span>SIM </span
      ><span style="font-family: 宋体">卡 </span><span>IMSI </span
      ><span style="font-family: 宋体"
        >信息、设备序列号）、需要分享的社交账户公开信息、设备运行进程信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">官方隐私政策：</span
      ><span>https://www.umeng.com/policy</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">友盟组件化基础服务</span><span>SDK</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">第三方主体：友盟同欣（北京）科技有限公司</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >使用目的：一键分享服务、统计分析服务并通过地理位置校准报表数据准确性，提供基础反作弊能力</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">收集信息类型：设备</span><span>Mac</span
      ><span style="font-family: 宋体">地址、唯一设备识别码（</span
      ><span>IMEI/android ID/IDFA/OPENUDID/GUID</span
      ><span style="font-family: 宋体">、</span><span>SIM </span
      ><span style="font-family: 宋体">卡 </span><span>IMSI </span
      ><span style="font-family: 宋体"
        >信息、设备序列号）、需要分享的社交账户公开信息、设备运行进程信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">官方隐私政策：</span
      ><span>https://www.umeng.com/policy</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt"><span>com.umeng.commonSDK</span></p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">第三方主体：友盟同欣（北京）科技有限公司</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >使用目的：一键分享服务、统计分析服务并通过地理位置校准报表数据准确性，提供基础反作弊能力</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">收集信息类型：设备</span><span>Mac</span
      ><span style="font-family: 宋体">地址、唯一设备识别码（</span
      ><span>IMEI/android ID/IDFA/OPENUDID/GUID</span
      ><span style="font-family: 宋体">、</span><span>SIM </span
      ><span style="font-family: 宋体">卡 </span><span>IMSI </span
      ><span style="font-family: 宋体"
        >信息、设备序列号）、需要分享的社交账户公开信息、设备运行进程信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">官方隐私政策：</span
      ><span>https://www.umeng.com/policy</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt"><span>com.efsSDK</span></p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">第三方主体：友盟同欣（北京）科技有限公司</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >使用目的：一键分享服务、统计分析服务并通过地理位置校准报表数据准确性，提供基础反作弊能力</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">收集信息类型：设备</span><span>Mac</span
      ><span style="font-family: 宋体">地址、唯一设备识别码（</span
      ><span>IMEI/android ID/IDFA/OPENUDID/GUID</span
      ><span style="font-family: 宋体">、</span><span>SIM </span
      ><span style="font-family: 宋体">卡 </span><span>IMSI </span
      ><span style="font-family: 宋体"
        >信息、设备序列号）、需要分享的社交账户公开信息、设备运行进程信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">官方隐私政策：</span
      ><span>https://www.umeng.com/policy</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt"><span>com.umengSDK</span></p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">第三方主体：友盟同欣（北京）科技有限公司</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >使用目的：一键分享服务、统计分析服务并通过地理位置校准报表数据准确性，提供基础反作弊能力</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">收集信息类型：设备</span><span>Mac</span
      ><span style="font-family: 宋体">地址、唯一设备识别码（</span
      ><span>IMEI/android ID/IDFA/OPENUDID/GUID</span
      ><span style="font-family: 宋体">、</span><span>SIM </span
      ><span style="font-family: 宋体">卡 </span><span>IMSI </span
      ><span style="font-family: 宋体"
        >信息、设备序列号）、需要分享的社交账户公开信息、设备运行进程信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">官方隐私政策：</span
      ><span>https://www.umeng.com/policy</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">阿里百川</span><span>SDK</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >第三方主体：浙江淘宝网络有限公司、淘宝（中国）软件有限公司、杭州阿里巴巴广告有限公司</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">使用目的：支持跳转到手机淘宝打开商品详情</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">收集信息类型：本机应用列表、设备</span
      ><span>Mac</span><span style="font-family: 宋体">地址、唯一设备识别码（</span
      ><span>IMEI/android ID/IDFA/OPENUDID/GUID</span
      ><span style="font-family: 宋体">、</span><span>SIM </span
      ><span style="font-family: 宋体">卡 </span><span>IMSI </span
      ><span style="font-family: 宋体"
        >信息）、运营商信息、网络状态信息、操作日志、设备运行进程信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">官方隐私政策：</span
      ><span
        >https://terms.alicdn.com/legal-agreement/terms/suit_bu1_taobao/suit_bu1_taobao202107141244_11649.html?spm=a3c0d.7662649.1998907877.3.55ccbe486scYvy</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt"><span>com.alibaba.mtlSDK</span></p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >第三方主体：浙江淘宝网络有限公司、淘宝（中国）软件有限公司、杭州阿里巴巴广告有限公司</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">使用目的：支持跳转到手机淘宝打开商品详情</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">收集信息类型：本机应用列表、设备</span
      ><span>Mac</span><span style="font-family: 宋体">地址、唯一设备识别码（</span
      ><span>IMEI/android ID/IDFA/OPENUDID/GUID</span
      ><span style="font-family: 宋体">、</span><span>SIM </span
      ><span style="font-family: 宋体">卡 </span><span>IMSI </span
      ><span style="font-family: 宋体"
        >信息）、运营商信息、网络状态信息、操作日志、设备运行进程信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">官方隐私政策：</span
      ><span
        >https://terms.alicdn.com/legal-agreement/terms/suit_bu1_taobao/suit_bu1_taobao202107141244_11649.html?spm=a3c0d.7662649.1998907877.3.55ccbe486scYvy</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt"><span>MemberSDK</span></p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >第三方主体：浙江淘宝网络有限公司、淘宝（中国）软件有限公司、杭州阿里巴巴广告有限公司</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">使用目的：支持跳转到手机淘宝打开商品详情</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">收集信息类型：本机应用列表、设备</span
      ><span>Mac</span><span style="font-family: 宋体">地址、唯一设备识别码（</span
      ><span>IMEI/android ID/IDFA/OPENUDID/GUID</span
      ><span style="font-family: 宋体">、</span><span>SIM </span
      ><span style="font-family: 宋体">卡 </span><span>IMSI </span
      ><span style="font-family: 宋体"
        >信息）、运营商信息、网络状态信息、操作日志、设备运行进程信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">官方隐私政策：</span
      ><span
        >https://terms.alicdn.com/legal-agreement/terms/suit_bu1_taobao/suit_bu1_taobao202107141244_11649.html?spm=a3c0d.7662649.1998907877.3.55ccbe486scYvy</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">淘宝</span><span>SDK</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >第三方主体：浙江淘宝网络有限公司、淘宝（中国）软件有限公司、杭州阿里巴巴广告有限公司</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">使用目的：支持跳转到手机淘宝打开商品详情</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">收集信息类型：本机应用列表、设备</span
      ><span>Mac</span><span style="font-family: 宋体">地址、唯一设备识别码（</span
      ><span>IMEI/android ID/IDFA/OPENUDID/GUID</span
      ><span style="font-family: 宋体">、</span><span>SIM </span
      ><span style="font-family: 宋体">卡 </span><span>IMSI </span
      ><span style="font-family: 宋体"
        >信息）、运营商信息、网络状态信息、操作日志、设备运行进程信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">官方隐私政策：</span
      ><span
        >https://terms.alicdn.com/legal-agreement/terms/suit_bu1_taobao/suit_bu1_taobao202107141244_11649.html?spm=a3c0d.7662649.1998907877.3.55ccbe486scYvy</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">阿里云风险识别</span><span>SDK</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">第三方主体：杭州阿里巴巴广告有限公司</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">使用目的：提供广告内容检查及风险识别服务</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">收集信息类型：本机应用列表、设备</span
      ><span>Mac</span><span style="font-family: 宋体">地址、唯一设备识别码（</span
      ><span>IMEI/android ID/IDFA/OPENUDID/GUID</span
      ><span style="font-family: 宋体">、</span><span>SIM </span
      ><span style="font-family: 宋体">卡 </span><span>IMSI </span
      ><span style="font-family: 宋体"
        >信息）、运营商信息、网络状态信息、设备运行进程信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">官方隐私政策：</span
      ><span
        >https://developer.alibaba.com/docs/doc.htm?spm=a219a.7395905.0.0.314075fef8jzpV&amp;articleId=101618&amp;docType=1&amp;treeId=1</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>com.taobao.wireless</span><span style="font-family: 宋体">安全组件</span
      ><span>SDK</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">第三方主体：杭州阿里巴巴广告有限公司</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">使用目的：提供广告内容检查及风险识别服务</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">收集信息类型：本机应用列表、设备</span
      ><span>Mac</span><span style="font-family: 宋体">地址、唯一设备识别码（</span
      ><span>IMEI/android ID/IDFA/OPENUDID/GUID</span
      ><span style="font-family: 宋体">、</span><span>SIM </span
      ><span style="font-family: 宋体">卡 </span><span>IMSI </span
      ><span style="font-family: 宋体"
        >信息）、运营商信息、网络状态信息、设备运行进程信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">官方隐私政策：</span
      ><span
        >https://developer.alibaba.com/docs/doc.htm?spm=a219a.7395905.0.0.314075fef8jzpV&amp;articleId=101618&amp;docType=1&amp;treeId=1</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span>com.alibaba.wirelessSDK</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">第三方主体：杭州阿里巴巴广告有限公司</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">使用目的：提供广告内容检查及风险识别服务</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">收集信息类型：本机应用列表、设备</span
      ><span>Mac</span><span style="font-family: 宋体">地址、唯一设备识别码（</span
      ><span>IMEI/android ID/IDFA/OPENUDID/GUID</span
      ><span style="font-family: 宋体">、</span><span>SIM </span
      ><span style="font-family: 宋体">卡 </span><span>IMSI </span
      ><span style="font-family: 宋体"
        >信息）、运营商信息、网络状态信息、设备运行进程信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">官方隐私政策：</span
      ><span
        >https://developer.alibaba.com/docs/doc.htm?spm=a219a.7395905.0.0.314075fef8jzpV&amp;articleId=101618&amp;docType=1&amp;treeId=1</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">阿里云快速</span><span>jsonSDK</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">第三方主体：杭州阿里巴巴广告有限公司</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">使用目的：提供广告内容检查及风险识别服务</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">收集信息类型：本机应用列表、设备</span
      ><span>Mac</span><span style="font-family: 宋体">地址、唯一设备识别码（</span
      ><span>IMEI/android ID/IDFA/OPENUDID/GUID</span
      ><span style="font-family: 宋体">、</span><span>SIM </span
      ><span style="font-family: 宋体">卡 </span><span>IMSI </span
      ><span style="font-family: 宋体"
        >信息）、运营商信息、网络状态信息、设备运行进程信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">官方隐私政策：</span
      ><span
        >https://developer.alibaba.com/docs/doc.htm?spm=a219a.7395905.0.0.314075fef8jzpV&amp;articleId=101618&amp;docType=1&amp;treeId=1</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">开普勒</span><span>SDK</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">第三方主体：北京京东叁佰陆度电子商务有限公司</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">使用目的：支持跳转到手机京东打开商品详情</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">收集信息类型：本机应用列表、设备</span
      ><span>Mac</span><span style="font-family: 宋体">地址、唯一设备识别码（</span
      ><span>IMEI/android ID/IDFA/OPENUDID/GUID</span
      ><span style="font-family: 宋体">、</span><span>SIM </span
      ><span style="font-family: 宋体">卡 </span><span>IMSI </span
      ><span style="font-family: 宋体"
        >信息）、运营商信息、网络状态信息、设备运行进程信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">官网地址：</span><span>https://k.jd.com/</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">穿山甲</span><span>SDK</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">第三方主体：北京巨量引擎网络技术有限公司</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">使用目的：提供效果广告相关服务</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">收集信息类型：设备</span><span>Mac</span
      ><span style="font-family: 宋体">地址、唯一设备识别码（</span
      ><span>IMEI/android ID/IDFA/OPENUDID/GUID</span
      ><span style="font-family: 宋体">、</span><span>SIM </span
      ><span style="font-family: 宋体">卡 </span><span>IMSI </span
      ><span style="font-family: 宋体"
        >信息）、运营商信息、网络状态信息、设备运行进程信息、陀螺仪传感器、加速度传感器、重力传感器</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">官网地址：</span
      ><span>https://ad.oceanengine.com/union/media/union/</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">巨量引擎数据上报</span><span>SDK</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">第三方主体：北京巨量引擎网络技术有限公司</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">使用目的：提供效果广告相关服务</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">收集信息类型：设备</span><span>Mac</span
      ><span style="font-family: 宋体">地址、唯一设备识别码（</span
      ><span>IMEI/android ID/IDFA/OPENUDID/GUID</span
      ><span style="font-family: 宋体">、</span><span>SIM </span
      ><span style="font-family: 宋体">卡 </span><span>IMSI </span
      ><span style="font-family: 宋体"
        >信息）、运营商信息、网络状态信息、设备运行进程信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">官网地址：</span
      ><span>https://ad.oceanengine.com/union/media/union/</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt"><span>com.bytedanceSDK</span></p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">第三方主体：北京巨量引擎网络技术有限公司</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">使用目的：提供效果广告相关服务</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">收集信息类型：设备</span><span>Mac</span
      ><span style="font-family: 宋体">地址、唯一设备识别码（</span
      ><span>IMEI/android ID/IDFA/OPENUDID/GUID</span
      ><span style="font-family: 宋体">、</span><span>SIM </span
      ><span style="font-family: 宋体">卡 </span><span>IMSI </span
      ><span style="font-family: 宋体"
        >信息）、运营商信息、网络状态信息、设备运行进程信息、陀螺仪传感器、加速度传感器、重力传感器</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">官网地址：</span
      ><span>https://ad.oceanengine.com/union/media/union/</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">腾讯优量汇</span><span>SDK</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">第三方主体：腾讯科技（深圳）有限公司</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">使用目的：提供效果广告相关服务</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">收集信息类型：设备</span><span>Mac</span
      ><span style="font-family: 宋体">地址、唯一设备识别码（</span
      ><span>IMEI/android ID/IDFA/OPENUDID/GUID</span
      ><span style="font-family: 宋体">、</span><span>SIM </span
      ><span style="font-family: 宋体">卡 </span><span>IMSI </span
      ><span style="font-family: 宋体"
        >信息）、运营商信息、网络状态信息、设备运行进程信息、陀螺仪传感器、加速度传感器、重力传感器</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">官网地址：</span
      ><span>https://e.qq.com/dev/index.html</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">百度联盟 </span><span>SDK</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">第三方主体：百度时代网络技术（北京）有限公司</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">使用目的：提供效果广告相关服务</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">收集信息类型：设备信息、设备</span><span>Mac</span
      ><span style="font-family: 宋体">地址、唯一设备识别码（</span
      ><span>IMEI/MEID/OAID/android ID/IDFA/OPENUDID/GUID</span
      ><span style="font-family: 宋体">、</span><span>SIM </span
      ><span style="font-family: 宋体">卡 </span><span>IMSI </span
      ><span style="font-family: 宋体"
        >信息）、运营商信息、网络状态信息、设备运行进程信息、陀螺仪传感器、加速度传感器、重力传感器</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">官网地址：</span
      ><span>https://union.baidu.com/bqt/#/legal/policies</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">快手联盟 </span><span>SDK</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">第三方主体：快手广告有限公司</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">使用名称：</span><span>kssdk-ad</span
      ><span style="font-family: 宋体">、</span><span>com.kwad.components</span
      ><span style="font-family: 宋体">、</span><span>com.kuaishou.weapon</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">使用目的：提供效果广告相关服务</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">收集信息类型：设备信息、设备</span><span>Mac</span
      ><span style="font-family: 宋体">地址、唯一设备识别码（</span
      ><span>IMEI/MEID/OAID/android ID/IDFA/OPENUDID/GUID/ICCID</span
      ><span style="font-family: 宋体">、</span><span>SIM </span
      ><span style="font-family: 宋体">卡 </span><span>IMSI </span
      ><span style="font-family: 宋体"
        >信息）、运营商信息、网络状态信息、设备运行进程信息、获取设备传感器、监听设备传感器、陀螺仪传感器、加速度传感器、重力传感器</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">官网地址：</span
      ><span>https://u.kuaishou.com/home/detail/1220</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt"><span>Gromore SDK</span></p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">第三方主体：北京巨量引擎网络技术有限公司</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">使用目的：提供效果广告相关服务</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">收集信息类型：设备信息、设备</span><span>Mac</span
      ><span style="font-family: 宋体">地址、唯一设备识别码（</span
      ><span>IMEI/MEID/OAID/android ID/IDFA/OPENUDID/GUID/ICCID</span
      ><span style="font-family: 宋体">、</span><span>SIM </span
      ><span style="font-family: 宋体">卡 </span><span>IMSI </span
      ><span style="font-family: 宋体"
        >信息）、运营商信息、网络状态信息、设备运行进程信息、陀螺仪传感器、加速度传感器、重力传感器</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">官网地址：</span
      ><span>https://www.csjplatform.com/terms/gromoresdk-privacy</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt"><span>QQ SDK</span></p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">第三方主体：腾讯科技（深圳）有限公司</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">使用目的：支持</span><span>QQ</span
      ><span style="font-family: 宋体">分享服务</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">收集信息类型：设备</span><span>Mac</span
      ><span style="font-family: 宋体">地址、唯一设备识别码（</span
      ><span>IMEI/android ID/IDFA/OPENUDID/GUID</span
      ><span style="font-family: 宋体">、</span><span>SIM </span
      ><span style="font-family: 宋体">卡 </span><span>IMSI </span
      ><span style="font-family: 宋体">信息）、需要分享的社交账户公开信息</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">官网地址：</span
      ><span>https://open.tencent.com/</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">微信 </span><span>SDK</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">第三方主体：腾讯科技（深圳）有限公司</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">使用目的：支持微信登录、分享、支付服务</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >收集信息类型：本机应用列表、设备型号、操作系统、设备</span
      ><span>Mac</span><span style="font-family: 宋体">地址、唯一设备识别码（</span
      ><span>IMEI/android ID/IDFA/OPENUDID/GUID</span
      ><span style="font-family: 宋体">、</span><span>SIM </span
      ><span style="font-family: 宋体">卡 </span><span>IMSI </span
      ><span style="font-family: 宋体">信息）、登录</span><span>IP</span
      ><span style="font-family: 宋体"
        >地址、微信软件版本号、接入网络的方式、类型和状态、网络质量数据、操作日志</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">官网地址：</span
      ><span>https://open.weixin.qq.com/</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt"><span>com.tencent.tauth SDK</span></p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">第三方主体：腾讯科技（深圳）有限公司</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">使用目的：支持</span><span>QQ</span
      ><span style="font-family: 宋体">分享服务</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">收集信息类型：设备</span><span>Mac</span
      ><span style="font-family: 宋体">地址、唯一设备识别码（</span
      ><span>IMEI/android ID/IDFA/OPENUDID/GUID</span
      ><span style="font-family: 宋体">、</span><span>SIM </span
      ><span style="font-family: 宋体">卡 </span><span>IMSI </span
      ><span style="font-family: 宋体">信息）、需要分享的社交账户公开信息</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">官网地址：</span
      ><span>https://open.tencent.com/</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt"><span>com.tencent.open SDK</span></p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">第三方主体：腾讯科技（深圳）有限公司</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">使用目的：支持微信登录、分享、支付服务</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >收集信息类型：本机应用列表、设备型号、操作系统、设备</span
      ><span>Mac</span><span style="font-family: 宋体">地址、唯一设备识别码（</span
      ><span>IMEI/android ID/IDFA/OPENUDID/GUID</span
      ><span style="font-family: 宋体">、</span><span>SIM </span
      ><span style="font-family: 宋体">卡 </span><span>IMSI </span
      ><span style="font-family: 宋体">信息）、登录</span><span>IP</span
      ><span style="font-family: 宋体"
        >地址、微信软件版本号、接入网络的方式、类型和状态、网络质量数据、操作日志</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">官网地址：</span
      ><span>https://open.weixin.qq.com/</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">微博 </span><span>SDK</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">第三方主体：北京微梦创科网络技术有限公司</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">使用目的：支持微博分享服务</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">收集信息类型：设备</span><span>Mac</span
      ><span style="font-family: 宋体">地址、唯一设备识别码（</span
      ><span>IMEI/android ID/IDFA/OPENUDID/GUID</span
      ><span style="font-family: 宋体">、</span><span>SIM </span
      ><span style="font-family: 宋体">卡 </span><span>IMSI </span
      ><span style="font-family: 宋体">信息）、需要分享的社交账户公开信息</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">官网地址：</span
      ><span>https://open.weibo.com/</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">支付宝 </span><span>SDK</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">第三方主体：支付宝（中国）网络技术有限公司</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">使用目的：提供支付宝手机</span><span>app</span
      ><span style="font-family: 宋体">第三方支付服务</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">收集信息类型：本机应用列表、设备</span
      ><span>Mac</span><span style="font-family: 宋体">地址、唯一设备识别码（</span
      ><span>IMEI/android ID/IDFA/OPENUDID/GUID</span
      ><span style="font-family: 宋体">、</span><span>SIM </span
      ><span style="font-family: 宋体">卡 </span><span>IMSI </span
      ><span style="font-family: 宋体">信息）、运营商信息、网络状态信息</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">官方说明或隐私政策：</span
      ><span>https://opendocs.alipay.com/open/54</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">第三方网站服务器的使用</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >为了保障功能实现，使您能够使用和享受更多的服务及功能，我们的应用中会使用授权合作伙伴的网站服务器。此时可能会收集您的相关信息。具体使用情况如下：</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">兴趣知识网站服务器</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">使用目的：支持数据统计与分析功能</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">收集信息类型：设备</span><span>Mac</span
      ><span style="font-family: 宋体">地址、唯一设备识别码（</span
      ><span>IMEI/android ID/IDFA/OPENUDID/GUID</span
      ><span style="font-family: 宋体">、</span><span>SIM </span
      ><span style="font-family: 宋体">卡 </span><span>IMSI </span
      ><span style="font-family: 宋体">信息）</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">广告服务</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >我们可能使用您的信息，向您提供与您更加相关的广告。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >我们也可能使用您的信息，通过我们的服务、电子邮件或其他方式向您发送营销信息，提供或推广我们或第三方的如下商品和服务：</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >我们的商品和服务，以及我们的关联公司和合作伙伴的商品和服务，包括即时通讯服务、网上媒体服务、互动娱乐服务、社交网络服务、付款服务、互联网搜索服务、应用软件和服务、数据管理软件和服务、网上广告服务、互联网金融，以及其他社交媒体、娱乐、电子商务、资讯及通讯软件和服务（统称“互联网服务”）；</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >第三方互联网服务供应商，以及与下列有关的第三方商品和服务：食物和餐饮、体育、音乐、电影、电视、现场表演及其他艺术和娱乐、书册、杂志和其他刊物、服装和配饰、珠宝、化妆品、个人健康和卫生、电子、收藏品、家用器皿、电器、家居装饰和摆设、宠物、汽车、酒店、交通和旅游、银行、保险及其他金融服务、会员积分和奖励计划，以及我们认为可能与您相关的其他商品和服务。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >如您不希望我们将您的个人信息用作前述广告用途，您可以通过我们在广告中提供的相关指示、或在特定服务中提供的指引，要求我们停止为上述用途使用您的个人信息。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">我们可能向您发送的邮件和信息</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">邮件和信息推送 </span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >您在使用我们的服务时，我们可能使用您的信息向您的设备发送电子邮件、新闻或推送通知。如您不希望收到这些信息，可以按照我们的相关提示，在设备上选择取消订阅。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">与服务有关的公告 </span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >我们可能在必需时（例如因系统维护而暂停某一项服务时）向您发出与服务有关的公告。您可能无法取消这些与服务有关、性质不属于推广的公告。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">隐私政策的适用例外</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >我们的服务可能包括或链接至第三方提供的社交媒体或其他服务（包括网站）。例如：</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >您利用“分享”键将某些内容分享到我们的服务，或您利用第三方连线服务登录我们的服务。这些功能可能会收集您的相关信息（包括您的日志信息），并可能在您的电脑装置 </span
      ><span>cookies</span><span style="font-family: 宋体">，从而正常运行上述功能；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >我们通过广告或我们服务的其他方式向您提供链接，使您可以接入第三方的服务或网站。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >该等第三方社交媒体或其他服务可能由相关的第三方或我们运营。您使用该等第三方的社交媒体服务或其他服务（包括您向该等第三方提供的任何个人信息），须受该第三方的服务条款及隐私政策（而非《通用服务条款》或本《隐私政策》）约束，您需要仔细阅读其条款。本《隐私政策》仅适用于我们所收集的信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，我们对任何第三方使用由您提供的信息不承担任何责任。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">未成年人使用我们的服务</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >我们鼓励父母或监护人指导未满十八岁的未成年人使用我们的服务。我们建议未成年人鼓励他们的父母或监护人阅读本《隐私政策》，并建议未成年人在提交的个人信息之前寻求父母或监护人的同意和指导。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">隐私政策的适用范围</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >除某些特定服务外，我们所有的服务均适用本《隐私政策》。这些特定服务将适用特定的隐私政策。针对某些特定服务的特定隐私政策，将更具体地说明我们在该等服务中如何使用您的信息。该特定服务的隐私政策构成本《隐私政策》的一部分。如任何特定服务的隐私政策与本《隐私政策》有不一致之处，则适用特定服务的隐私政策。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >除本《隐私政策》另有规定外，本《隐私条款》所用词语将与《树枝记账软件许可及服务协议》（</span
      ><span>http://www.shayujizhang.com/about/privacy.html</span
      ><span style="font-family: 宋体">）所定义的词语具有相同的涵义。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">请您注意，本《隐私政策》不适用于以下情况：</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >通过我们的服务而接入的第三方服务（包括任何第三方网站）收集的信息；</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >通过在我们服务中进行广告服务的其他公司和机构所收集的信息。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">变更</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体"
        >我们可能适时修订本《隐私政策》的条款，该等修订构成本《隐私政策》的一部分。如该等修订造成您在本《隐私政策》下权利的实质减少，我们将在修订生效前通过在主页上显著位置提示或向您发送电子邮件或以其他方式通知您，在该种情况下，若您继续使用我们的服务，即表示同意受经修订的本《隐私政策》的约束。</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="font-family: 宋体">厦门逆为网络科技有限公司</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "privacy",
};
</script>

<style lang="less" scoped>
.privacy {
  padding: 15px;
  .right {
    text-align: right;
  }
  .title {
    font-size: 18px;
    color: #333;
    text-align: center;
    line-height: 36px;
    padding: 10px;
  }
  .content {
    text-indent: 25px;
  }
  .content em {
    font-style: normal;
    text-decoration: underline;
  }
  .content b {
    color: #333;
    text-decoration: underline;
  }
  .bold {
    font-weight: bold;
  }
  p {
    font-size: 14px;
    color: #333;
    line-height: 23px;
    margin-bottom: 7px;
    word-break: break-all;
  }
}
</style>
